import store from "@/state/store";

const routes = [
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/auth/pages/login.vue"),
		meta: {
			title: "Login",
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "dashboard" });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/logout",
		name: "logout",
		meta: {
			title: "Logout",
			authRequired: true,
			beforeResolve(routeTo, routeFrom, next) {
				localStorage.clear();
				sessionStorage.clear();
				next({ name: "login" });
			},
		},
	},
	{
		path: "/reset-password",
		name: "Reset password",
		component: () => import("@/views/auth/pages/reset-password.vue"),
		meta: {
			title: "Reset Password",
		},
	},
	{
		path: "/login/setup-profile",
		name: "setup-profile",
		component: () => import("@/views/auth/pages/setup-profile.vue"),
		meta: {
			title: "Setup Profile",
			authRequired: true,
			cacheable: true,
			requiresOnline: true,
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "dashboard" });
				} else {
					// Continue to the login page
					next();
				}
			},
		},
	},
	{
		path: "/member/2736fab291f04e69b62d490c3c09361f5b82461a",
		name: "v2-member",
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "dashboard" });
				} else {
					// Continue to the login page
					next({ name: "login" });
				}
			},
		},
	},
	{
		path: "/hq/2736fab291f04e69b62d490c3c09361f5b82461a",
		name: "v2-hq",
		meta: {
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next({ name: "dashboard" });
				} else {
					// Continue to the login page
					next({ name: "login" });
				}
			},
		},
	},

	// ============================
	//  administrator
	// ============================
	// my plan & rewards
	{
		path: "/plan-rewards",
		name: "plan reward",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/administrator/plan-reward/index"),
	},
	{
		path: "/plan-rewards/rewards-details/:id",
		name: "reward-details",
		meta: { title: "My Plan & Rewards", authRequired: true },
		component: () =>
			import("@/views/administrator/plan-reward/pages/reward-details"),
	},
	{
		path: "/plan-rewards/plan-subscription",
		name: "plan-subscription",
		meta: { title: "My Plan & Rewards", authRequired: true },
		component: () =>
			import("@/views/administrator/plan-reward/pages/plan-subscription"),
	},

	{
		path: "/pages/profile",
		name: "update-profile",
		meta: { title: "Profile", authRequired: true },
		component: () => import("../views/auth/pages/setup-profile"),
	},

	// product routing

	{
		path: "/products",
		name: "products",
		meta: { title: "Products", authRequired: true },
		component: () =>
			import("../views/preference/products/single-products/index"),
	},
	{
		path: "/products/new",
		name: "new-products",
		meta: { title: "Products", authRequired: true },
		component: () =>
			import("../views/preference/products/single-products/pages/add-product"),
	},
	{
		path: "/products/edit/:id",
		name: "edit-products",
		meta: { title: "Products", authRequired: true },
		component: () =>
			import("../views/preference/products/single-products/pages/edit-product"),
	},
	{
		path: "/products/preview-store",
		name: "preview-store",
		meta: { title: "Store Preview", authRequired: true },
		component: () =>
			import(
				"../views/preference/products/single-products/pages/preview-store"
			),
	},
	{
		path: "/products/preview-product/:productId",
		name: "preview-product",
		meta: { title: "Product Preview", authRequired: true },
		component: () =>
			import(
				"../views/preference/products/single-products/pages/product-details"
			),
	},

	// inventory routing

	{
		path: "/inventory",
		name: "inventory",
		meta: { title: "Inventory", authRequired: true },
		component: () => import("../views/preference/products/inventory/index"),
	},
	{
		path: "/inventory/update",
		name: "inventory-update",
		meta: { title: "Inventory", authRequired: true },
		component: () =>
			import("../views/preference/products/inventory/pages/bulk-update"),
	},

	// courier routing

	{
		path: "/courier",
		name: "courier",
		meta: { title: "Courier", authRequired: true },
		component: () => import("../views/preference/courier/index"),
	},
	{
		path: "/courier/new",
		name: "new-courier",
		meta: { title: "Courier", authRequired: true },
		component: () => import("../views/preference/courier/pages/add-courier"),
	},

	// ============================
	//  agent management
	// ============================
	// agent database
	{
		path: "/agent-database",
		name: "agent-database",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/agent-management/agent-database/index"),
	},
	// agent details
	{
		path: "/agent-details",
		name: "agent-details",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/agent-management/agent-details/index"),
	},
	// agent info
	{
		path: "/agent-info",
		name: "agent-info",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/agent-management/agent-info/index"),
	},
	// agent family tree
	{
		path: "/agent-family-tree",
		name: "agent-family-tree",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/agent-management/family-tree/index"),
	},
	// promote demote
	{
		path: "/promote-demote",
		name: "promote-demote",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/agent-management/promote-demote/index"),
	},
	// agent registration link
	{
		path: "/agent-registration",
		name: "agent-registration",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/agent-management/registration/index"),
	},
	// agent registration new
	{
		path: "/new-registration/:username/:leaderName/:leaderTier/:leaderId/:tierName/:tierId/:companyId",
		name: "register-agent",
		meta: { cacheable: false, requiresOnline: true },
		component: () =>
			import("@/views/agent-management/registration/pages/registration-link"),
	},
	// agent registration after success register -> move to -> verify-ing email page (KIV)
	{
		path: "/register/verify",
		name: "verify",
		component: () => import("@/views/auth/pages/verify.vue"),
		meta: { cacheable: true, requiresOnline: true },
	},
	// show success verified email page
	{
		path: "/register/verified",
		name: "verified",
		component: () => import("@/views/auth/pages/verified.vue"),
		meta: { cacheable: true, requiresOnline: true },
	},

	// ============================
	//  e-commerce
	// ============================
	// manage order
	{
		path: "/manage-orders",
		name: "manage-orders",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/manage-order/pages/index"),
	},
	// new order
	{
		path: "/manage-orders/new",
		name: "new-orders",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/manage-order/pages/add-order"),
	},
	// order details
	{
		path: "/manage-orders/order-details",
		name: "order-details",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/e-commerce/manage-order/pages/order-details"),
	},
	// invoice
	{
		path: "/manage-orders/order-details/invoice",
		name: "order-invoice",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/e-commerce/manage-order/pages/invoices"),
	},
	// packing list
	{
		path: "/manage-orders/order-details/packing-list",
		name: "order-packing-list",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/e-commerce/manage-order/pages/packing-list"),
	},

	// packing list
	{
		path: "/manage-orders/update-pickup",
		name: "update-pickup",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/e-commerce/manage-order/pages/update-pickup"),
	},

	{
		path: "/manage-orders/order-details/shipping-doc",
		name: "shipping-doc",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/e-commerce/manage-order/pages/shipping-doc"),
	},

	{
		path: "/manage-orders/order-details/shipping-template",
		name: "shipping-template",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/e-commerce/manage-order/pages/shipping-template"),
	},

	// my shop
	{
		path: "/my-shop",
		name: "my-shop",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/myshop/pages/index"),
	},
	// product details
	{
		path: "/my-shop/product-details",
		name: "product-details",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/myshop/pages/product-details"),
	},
	// shopping cart
	{
		path: "/my-shop/shopping-cart",
		name: "shopping-cart",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/shopping-cart/pages/index"),
	},
	// checkout
	{
		path: "/my-shop/checkout",
		name: "checkout",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/index"),
	},
	// pending payment
	{
		path: "/my-shop/checkout/pending-payment",
		name: "pending-payment",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/pending-payment"),
	},
	// checkout completed
	{
		path: "/my-shop/checkout/completed",
		name: "checkout-completed",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/completed"),
	},
	// checkout failed
	{
		path: "/my-shop/checkout/failed",
		name: "checkout-failed",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/failed"),
	},
	// ejenpay
	{
		path: "/ejenpay/:payType",
		name: "ejenpay",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/ejenpay"),
	},
	// ejenpay topup
	{
		path: "/ejenpay-topup/:amount",
		name: "ejenpay-topup",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/ejenpay-topup.vue"),
	},

	// pending topup
	{
		path: "/ejenpay/topup/pending-payment",
		name: "pending-topup",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/pending-topup.vue"),
	},

	// Completed TopUp
	{
		path: "/ejenpay-topup/completed",
		name: "completed-topup",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/completed-topup.vue"),
	},
	//  Failed Top Up
	{
		path: "/ejenpay-topup/failed",
		name: "failed-topup",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/e-commerce/checkout/pages/failed-topup.vue"),
	},


	// ============================
	//  marketing
	// ============================
	// files
	{
		path: "/files",
		name: "files",
        meta: { title: "Marketing Tools", authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/marketing/files/index"),
	},
	{
		path: "/files/folders",
		name: "folders",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/marketing/files/pages/folder-details.vue"),
	},
	// newsfeed
	{
		path: "/newsfeed",
		name: "newsfeed",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/marketing/newsfeed/index"),
	},
	// voucher
	{
		path: "/voucher",
		name: "voucher",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/marketing/voucher/index"),
	},
	{
		path: "/voucher/new",
		name: "add-voucher",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/marketing/voucher/pages/add-voucher"),
	},
	{
		path: "/voucher/edit",
		name: "edit-voucher",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/marketing/voucher/pages/edit-voucher"),
	},
	{
		path: "/voucher/history",
		name: "history-voucher",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/marketing/voucher/pages/history-voucher"),
	},

	// campaign
	{
		path: "/campaign-list",
		name: "campaign",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () => import("@/views/marketing/campaign/index"),
	},
	{
		path: "/campaign/point-history",
		name: "campaign-point-history",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () =>
			import("@/views/marketing/campaign/pages/campaign-point-history"),
	},
	{
		path: "/campaign/redeemed-history",
		name: "campaign-redeemed-history",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () =>
			import("@/views/marketing/campaign/pages/campaign-redeemed-history"),
	},
	{
		path: "/campaign/win-prize",
		name: "campaign-win-prize",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () =>
			import("@/views/marketing/campaign/pages/campaign-win-prize"),
	},

	{
		path: "/point-rewards",
		name: "point-rewards",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () =>
			import("@/views/reporting-analytics/home/pages/pointRewards/index"),
	},
	{
		path: "/point-rewards/point-history",
		name: "point-history",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () =>
			import("@/views/reporting-analytics/home/pages/pointRewards/pointHistory"),
	},
	{
		path: "/point-rewards/redeemed-rewards",
		name: "redeemed-rewards",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () =>
			import("@/views/reporting-analytics/home/pages/pointRewards/redeemedRewards"),
	},
	{
		path: "/point-rewards/reward-details",
		name: "reward-details",
		meta: { authRequired: true, cacheable: false, requiresOnline: true },
		component: () =>
			import("@/views/reporting-analytics/home/pages/pointRewards/rewardDetails"),
	},

	// ============================
	//  preference
	// ============================
	// company
	{
		path: "/company",
		name: "company",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/preference/company/index"),
	},
	// courier
	{
		path: "/courier",
		name: "courier",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/preference/courier/index"),
	},
	// add new courier
	{
		path: "/courier/new",
		name: "new-courier",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/preference/courier/pages/add-courier"),
	},
	// edit courier
	{
		path: "/courier/edit",
		name: "edit-courier",
		meta: { title: "Courier", authRequired: true },
		component: () => import("@/views/preference/courier/pages/edit-courier"),
	},
	// campaign
	{
		path: "/campaign",
		name: "setup-campaign",
		meta: { title: "Campaign", authRequired: true },
		component: () => import("@/views/preference/gamification/campaign/index"),
	},
	{
		path: "/campaign/new",
		name: "new-campaign",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/gamification/campaign/pages/add-campaign"),
	},
	{
		path: "/campaign/edit",
		name: "edit-campaign",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/gamification/campaign/pages/edit-campaign"),
	},
	{
		path: "/campaign/redeem-history",
		name: "redeem-history",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/gamification/campaign/pages/redeem-history"),
	},
	// integration
	{
		path: "/integration",
		name: "integration",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/preference/integrations/index"),
	},
	// product combo
	{
		path: "/combo",
		name: "combo",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/preference/products/combo/index"),
	},
	// product inventory
	{
		path: "/inventory",
		name: "inventory",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/preference/products/inventory/index"),
	},
	// update product inventory
	{
		path: "/inventory/update",
		name: "inventory-update",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/products/inventory/pages/bulk-update"),
	},
	// product package
	{
		path: "/products/package",
		name: "product-package",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/preference/products/package/index"),
	},
	// add new product package
	{
		path: "/products/package/new",
		name: "product-package-new",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/products/package/pages/add-package"),
	},
	// edit product package
	{
		path: "/products/package/edit",
		name: "product-package-edit",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/products/package/pages/edit-package"),
	},
	// product
	{
		path: "/products",
		name: "products",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/products/single-products/index"),
	},
	// add new product
	{
		path: "/products/new",
		name: "new-products",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/products/single-products/pages/add-product"),
	},
	// preview store
	{
		path: "/products/preview-store",
		name: "preview-store",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/preference/products/single-products/pages/preview-store"),
	},
	// preview product details
	{
		path: "/products/preview-product",
		name: "preview-product",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import(
				"@/views/preference/products/single-products/pages/product-details"
			),
	},

	// ============================
	//  reporting-analytic
	// ============================
	// analytics (done) api tak siap
	{
		path: "/analytics",
		name: "analytics",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/reporting-analytics/analytics/index"),
	},

	//settlement-report
	{
		path: "/settlement-report",
		name: "settlement-report",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/reporting-analytics/settlement-report/index"),
	},

	// commission
	{
		path: "/commission",
		name: "commission",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/reporting-analytics/commission/index"),
	},
	{
		path: "/commission/details",
		name: "commission-details",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/reporting-analytics/commission/pages/commission-details"),
	},
	{
		path: "/commission/income-statement",
		name: "commission-income-statement",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import(
				"@/views/reporting-analytics/commission/pages/commission-income-statement"
			),
	},
	{
		path: "/commission/check-calculation",
		name: "commission-check-calculation",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import(
				"@/views/reporting-analytics/commission/pages/commission-calculations"
			),
	},
	// commission-payout
	{
		path: "/commission-payout",
		name: "commission-payout",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/reporting-analytics/commission-payout/index"),
	},
	{
		path: "/commission-payout/details",
		name: "commission-payout-details",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import("@/views/reporting-analytics/commission-payout/pages/commission-details"),
	},
	{
		path: "/commission-payout/income-statement",
		name: "commission-payout-income-statement",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import(
				"@/views/reporting-analytics/commission-payout/pages/commission-income-statement"
			),
	},
	{
		path: "/commission-payout/check-calculation",
		name: "commission-payout-check-calculation",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () =>
			import(
				"@/views/reporting-analytics/commission-payout/pages/commission-calculations"
			),
	},
	// home
	{
		path: "/home",
		name: "home",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/reporting-analytics/home/index"),
	},
	// dashboard (done) api tak siap
	{
		path: "/",
		name: "dashboard",
		meta: {
			authRequired: true,
			cacheable: true,
			requiresOnline: true,
			beforeResolve(routeTo, routeFrom, next) {
				// If the user is already logged in
				if (store.getters["auth/loggedIn"]) {
					// Redirect to the home page instead
					next();
				} else {
					// Continue to the login page
					next({ name: "login" });
				}
			},
		},
		component: () => import("@/views/reporting-analytics/dashboard/index"),
	},
	// reporting (done) api tak siap
	{
		path: "/reporting",
		name: "reporting",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/reporting-analytics/reporting/index"),
	},
	// ejen store
	{
		path: "/go-affiliate/register",
		name: "go-affiliate-register",
		meta: {authRequired: true, cacheable: true, requiresOnline: true},
		component: () => import("@/views/ejenstore/register/index"),
	},
	{
		path: "/go-affiliate/account",
		name: "go-affiliate-account",
		meta: {authRequired: true, cacheable: true, requiresOnline: true},
		component: () => import("@/views/ejenstore/register/account"),
	},
	{
		path: "/go-affiliate/setup",
		name: "go-affiliate-setup",
		meta: {authRequired: true, cacheable: true, requiresOnline: true},
		component: () => import("@/views/ejenstore/setup/index"),
	},
	{
		path: "/go-affiliate/store-summary",
		name: "summary-customer",
		meta: {authRequired: true, cacheable: true, requiresOnline: true},
		component: () => import("@/views/ejenstore/report/agent-report.vue"),
	},
	// score & ranking
	{
		path: "/score-ranking",
		name: "score-ranking",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/reporting-analytics/score-ranking/index"),
	},
	// setup HOF
	{
		path: "/score-ranking/hall-of-fame",
		name: "hall-of-fame",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/reporting-analytics/score-ranking/pages/hall-of-fame"),
	},

	// ============================
	//  user-management
	// ============================
	// faq (done) uiux tak siap
	{
		path: "/faqs",
		name: "faqs",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/user-management/faq/index"),
	},
	// helpline (done) api tak siap
	{
		path: "/helpline",
		name: "helpline",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/user-management/helpline/index"),
	},
	// user profile (done)
	{
		path: "/profile",
		name: "profile",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/user-management/profile/index"),
	},
	// forget password (done)
	{
		path: "/forgot-password",
		name: "Forgot password",
		component: () => import("@/views/auth/pages/forgot-password.vue"),
		meta: {
			title: "Forgot Password",
		},
	},

	// ============================
	//  Error Page
	// ============================
	// 404 Not Found (done)
	{
		path: "/:pathMatch(.*)*",
		name: "404-not-found",
		meta: { cacheable: true, requiresOnline: true },
		component: () => import("@/views/errors/404"),
	},
	{
		path: "/no-access",
		name: "404-no-access",
		meta: { cacheable: true, requiresOnline: true },
		component: () => import("@/views/errors/404"),
	},
	// offline page (done)
	{
		path: "/offline",
		name: "offline",
		meta: { cacheable: true, requiresOnline: false },
		component: () => import("@/views/errors/offline"),
	},
	// 500 internal server error (done)
	{
		path: "/500",
		name: "500-server-error",
		meta: { cacheable: true, requiresOnline: true },
		component: () => import("@/views/errors/500"),
	},
	{
		path: "/:pathMatch(.*)*",
		name: "subscription-expired",
		meta: { cacheable: true, requiresOnline: true },
		component: () => import("@/views/errors/subscription-expired"),
	},
	// ====================================================
	// 				Gameplan
	// ====================================================
	{
		path: "/gameplan",
		name: "gameplan",
		meta: { authRequired: true, cacheable: true, requiresOnline: true },
		component: () => import("@/views/administrator/gameplan/index"),
	},
];

export default routes;
